.active_tr th {
  font-size: 15px !important;
  font-weight: normal !important;
  text-align: center;
}
.active_tr td {
  text-align: center !important;
  font-size: 15px !important;
  color: #1d1d1d;
  border-bottom: 0px;
}
.bg-odd {
  background-color: white !important;
}
.bg-even {
  background-color: #f9f8ff !important;
}
.paggi .s-btn {
  font-size: 19px;
  padding: 4px 6px;
  border-radius: 4px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #dfdfdf;
  color: #1f5da0;
}
/* .paggi .s-btn:hover{
    color: white;
    background: #1F5DA0;
    transition: .4s all ease;
} */
.paggi .pointer {
  cursor: pointer;
}
.paggi .s-active {
  color: white;
  background: #1f5da0;
}
.modal-btn {
  padding: 8px 8px;
  border-radius: 50%;
  font-size: 18px;
}
.modal_data {
  display: flex;
  justify-content: center;
}
.modal_data div {
  width: 350px !important;
  border-bottom: 0.5px solid #1f5da0;
  padding: 6px 5px;
}
.user_img .inp{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  opacity: 0;
}
