.dashboard-card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 26px 32px;
}
.dashboard-info p {
  color: #bdbdbd !important;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}
.dashboard-info h5 {
  font-weight: 600;
  font-size: 23px;

  color: #000000;
}
.dashboard-card p {
  font-weight: 400;
  font-size: 16px;

  color: #000000;
}
.dashboard-card h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  /* Gray 1 */

  color: #333333;
}
.mute-text {
  font-weight: 400;
  font-size: 14px !important;
  color: #828282 !important;
}
.normal-text {
  font-weight: 400;
  font-size: 16px !important;
  color: #333333 !important;
}
.input-date {
  border: none;
  background: #f2f5f9;
  font-weight: 400;
  font-size: 16px;

  color: #1e2553;
}
.dashboard_date {
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 15px 0px 15px 23px;
  background: #f2f5f9;
  border-radius: 12px;
}
.input-date::-webkit-calendar-picker-indicator {
  color: #1e2553;
}
.input-date:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.area-h {
  height: 450px !important;
}
.history_div {
  padding: 6px;
  cursor: pointer;
}
.history_div:hover {
  background-color: #efeff1;
}
.history_div:hover .delete {
  color: red;
  fill: red;
}
.delete {
  height: 22px;
}
.delete:hover {
  color: crimson;
  /* fill: rebeccapurple; */
}
.cards .data {
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  flex-wrap: wrap;
}
.data{
  flex-basis: 23%;
  color: #1D1D1DBF;
}
.data h5{
  font-size: 30px;
}
.data p{
  font-size: 15px;
}
