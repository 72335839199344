/* @import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css"; */
/* @import '../../node_modules/@syncfusion/ej2/material.css'; */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: 'Inter', sans-serif !important;
  /* font-family: "Open Sans", sans-serif !important; */
  /* word-wrap: break-word; */
  /* transition: .4s all ease !important; */
}
.topContainer {
  display: flex;
}
body {
  height: 100%;
  background-color: #f5f5f5 !important;
}
.sidebar {
  /* width: 345px; */
  position: fixed;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
  left: 0;
  top: 0;
  /* background-color: #015642; */
}
.main-content {
  margin-left: 270px;
}
.main-content-collapsed {
  margin-left: 80px;
}


/*.open-sans {*/
/*  font-family: "DM Sans", sans-serif;*/
/*  font-weight: 400;*/
/*}*/
/*.table {*/
/*  border-radius: none !important;*/
/*}*/
/*.table tr:last-child {*/
/*  border-radius: 0 !important;*/
/*}*/
/*.table :where(:last-child) :where(:last-child) :where(th, td):first-child {*/
/*  border-radius: 0 !important;*/
/*}*/
/*.table :where(:last-child) :where(:last-child) :where(th, td):last-child {*/
/*  border-radius: 0 !important;*/
/*}*/
/*.table tr:last-child {*/
/*  border-radius: 5px !important;*/
/*}*/
/*input {*/
/*  margin-left: -10px;*/
/*}*/
/*.data {*/
/*  padding-left: 15px !important;*/
/*}*/
/*input:focus {*/
/*  outline: 2px solid #bdbdbd;*/
/*  padding-left: 5px;*/
/*}*/
/*@media screen and (min-width: 0px) and (max-width: 1230px) {*/
/*  .c_id {*/
/*    background: #1e2553 !important;*/
/*    color: white !important;*/
/*  }*/
/*}*/
/*@media screen and (min-width: 0px) and (max-width: 1170px) {*/
/*  .a_id {*/
/*    background: #1e2553 !important;*/
/*    color: white !important;*/
/*  }*/
/*}*/
/*.table>:not(caption)>*>* {*/
/*  padding: 0.6rem 0.75rem !important;*/
/*}*/
/*.border {*/
/*  border: 0.4px solid #dee2e6!important;*/
/*}*/
.bg-extra{
  background: #F5F5F5 !important;
  height: 100% !important;
  overflow-y: hidden;
}
.box-wrapper {
  height: 100%;
  width: 100%;
  padding: 34px 40px;
  
}
.icon {
  color: #b3adad;
  font-size: 21px;
}






.edit-del {
  display: flex ;
  justify-content: center !important;
  align-items: center !important;
  /* background-color: #F2F2F2 !important; */
  cursor: pointer;
  padding: 5px 5px;
  border-radius: 4px;
}
.table>thead {
  vertical-align: middle !important;
}
.true{
  color: #11DB62 !important;
}
.false{
  color: #FE2C41 !important;
}
.MuiPopover-paper{
  top: 88px !important;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
  background-color: white !important;
}
/* .pro-sidebar .pro-menu a{
  
} */
.pro-sidebar .pro-menu a {
  color: #1F5DA0 !important;
}