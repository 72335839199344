* {
  font-family: "Inter", sans-serif;
}
.sidebar {
  height: 100%;
  border-radius: 0px 30px 30px 0px;
  position: fixed;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
}
.logo {
  /* margin-bottom: 20px;
  margin-top: 20px; */
  padding: 15px 10px;
  text-align: center;
  background-color: #1F5DA0 !important;
}
.logo img {
  height: 40px;
}
.sidebarWrapper {
  /* margin-top: 25px; */
  font-weight: 600;
  font-size: 19px;
  /* line-height: 24px; */

  color: #e0e0e0;
}
.sidebarMenu {
  margin-bottom: 10px;
  font-size: 18px;
}
.sidebarTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  margin: 5px;
  border-radius: 5px;
}
.sidebarContainer {
  display: flex;

  align-items: center;
  padding: 0 5px;
  margin: 5px;
  border-radius: 5px;
}
.sidebarTitle {
  font-size: 18px;
  cursor: pointer;
  margin-top: 9px;
  /* margin-left: 5px; */
}

.sidebarList {
  list-style: none;
  padding: 5px;
  cursor: pointer;
}
.sidebarListItem {
  /* width: 90px; */
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  align-items: center;
  border-radius: 5px;
  display: flex;
  /* justify-content: space-between; */
}

.listDecoration {
  text-decoration: none;
}

.pro-sidebar > .pro-sidebar-inner {
  background-color: #e0e0e0 !important;
  height: 100vh;

  position: fixed;
  position: -webkit-sticky;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:active {
  color: rgb(239 233 233) !important;
}
.pro-sidebar {
  color: #e0e0e0 !important;
}
.closemenu {
  cursor: pointer;
  display: flex;
}
.sidebarTitleContainer a {
  margin-top: 0;
}

.main-content.collapsed {
  width: 100px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: white;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  background-color: white;
  color: black;
  border-radius: 8px;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  background-color: white !important;
}
.pro-sidebar .pro-menu a {
  color: #1F5DA0 !important;
}

li.pro-menu-item.active {
  background-color: white;
  /* border-radius: 4px; */
}
li.pro-sub-menu.pro-menu-item.active {
  background-color: white;
  /* border-radius: 4px; */
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  border-color: #1F5DA0 !important;
  border-width: 0 1px 1px 0 !important;
  padding: 3.5px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  font-weight: 500;
  font-size: 14px;
  color: #1F5DA0 !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 5px 8px 10px !important;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:active {
  background-color: white !important;
  border-radius: 4px;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu.active {
  background-color: white !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  /* padding-left: 20px; */
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 15px 12px 15px 15px !important;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  margin-bottom: none !important;
}

/* SideNav Hover margin */

.pro-sidebar .pro-menu .pro-menu-item {
  margin: 8px 10px;
  border-radius: 8px;
}

@media (max-width: 575.98px) {
  .pro-sidebar {
    width: 80px !important;
  }
}

@media (max-width: 767.98px) {
  .pro-sidebar {
    width: 80px !important;
  }
  .logo {
    /* margin-bottom: 20px;
    margin-top: 20px; */
    padding: 10px 5px;
    text-align: center;
    background-color: #1F5DA0 !important;
  }
}

@media (max-width: 991.98px) {
  .pro-sidebar {
    width: 80px !important;
  }
}

@media (max-width: 1199.98px) {
}
