.content_div {
  padding: 30px 60px;
}
.content_div .picture p {
  color: #1f5da0;
  font-size: 17px;
}
.select_img {
  position: relative;
  border: 1px solid #1f5da0;
  border-radius: 8px;
  width: 140px;
  height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  cursor: pointer;
}
.cross{
    border-radius: 50%;
    padding: 5px ;
    position: absolute;
    border: 1px solid #1f5da0;
    top: -10px;
    right: -10px;
    background-color: white;
}
.df {
  display: flex;
  justify-content: center;
  position: relative;
}
.df input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 100;
}
.global p{
    color: #1f5da0;
    font-size: 16px;
    /* margin-bottom: -1px; */
}
.global input ,.input{
    width: 100%;
    border-radius: 10px;
    border: 1px solid #1f5da0;
    outline: none;
    padding-left: 15px;
    height: 45px;
    margin-top: -5px !important;
    color: #1D1D1D8A !important;
}

.global textarea{
    width: 100%;
    min-height: 110px;
    border-radius: 10px;
    border: 1px solid #1f5da0;
    outline: none;
    padding-left: 15px ;
    padding-top: 5px;
    padding-bottom: 5px;
  color: #1D1D1D8A !important;
    margin-top: -5px !important;
}
.global button {
    border: 1px solid #1f5da0;
    outline: none;
    color: white;
    background-color: #1f5da0;
    padding: 12px 25px;
    border-radius: 8px;
}
.save{
    border: 1px solid #1f5da0;
    outline: none;
    color: white;
    background-color: #1f5da0;
    padding: 12px 35px;
    border-radius: 8px;
}
.global button:hover .save:hover{
    background-color: white;
    color: #1f5da0;
    transition: .5s all ease;
}
.global2 .back{
    outline: none;
    border: 1px solid #1f5da0;
    padding: 12px 35px;
    border-radius: 8px;
}
