.add_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #727272;
  position: relative;
  cursor: pointer;
}
.add_img input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 22;
}
.group_data {
  display: flex;
  justify-content: center;
  align-items: center;
}
.group_data .input_field {
  min-width: 70%;
  border-bottom: 1px solid #1f5da0;
  padding-bottom: 5px;
  padding-left: 6px;
}
.group_data .input_field input {
  width: 94%;
  border: none;
  outline: none;
}
.smily {
  cursor: pointer;
  color: #c2c2c2;
}
.users {
  border: 1px solid #1f5da0;
  border-radius: 4px;
  min-width: 70%;
  margin-top: 20px;
  min-height: 100px;
}
.group_member {
  border-radius: 15px;
  background: #ececec;
  height: 35px;
  align-items: center;
  /* justify-content: space-between; */
  display: flex;
}
.group_member img {
  margin-left: -5px !important;
  margin-bottom: -3px !important;
}
.gp_icon {
  margin-top: 3px;
}
.save_group {
  /* height: 48px; */
  color: white;
  background: #1f5da0;
  border-radius: 5px;
  border: 1px solid #1f5da0;
  outline: none;
  padding: 8px 50px;
  font-size: 18px;
}
.save_group:hover{
    color: #1f5da0;
    background-color: white;
    transition: .6s all ease;
}
.css-13cymwt-control{
  border-color: #1F5DA0 !important;
}
