.upper {
  display: flex;
  justify-content: space-between;
}
.upper h5 {
  font-size: 32px;
  color: #1d1d1d;
}
.upper_user {
  border: 3px solid #1f5da0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.upper_user img {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.lower {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lower .input {
  background-color: transparent;
  width: 450px;
  height: 40px !important;
  border: 1px solid #1f5da0;
  border-radius: 4px;
  position: relative;
}
.lower .input input {
  background: transparent !important;
  width: 90% !important;
  border: none;
  outline: none;
  height: 100%;
  padding-left: 15px;
}
.lower .input button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 4px;
  outline: none;
  background-color: #1f5da0;
  border: none;
  padding: 3px 8px;
}
.add-user-btn {
  border: 1px solid #1f5da0;
  outline: none;
  padding: 8px 10px;
  background-color: #1f5da0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (max-width: 767px) {
  .upper h5 {
    font-size: 24px;
  }
  .lower .input {
    width: 300px;
  }
  .icon {
    display: none;
  }
  .icon span{
    font-size: 14px;
  }
 
}
@media (max-width: 540px) {
  .upper h5 {
    font-size: 18px;
  }
  .upper p {
    font-size: 14px;
  }
  .icon {
    display: none;
  }
}
@media (max-width: 590px) {
  .lower{
    flex-direction: column;
    justify-content: start;
  }
  .add-user-btn{
    margin-top: 10px;
  }
  .lower .input {
    width: 200px;
  }
}
